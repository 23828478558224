/* Override some defaults of Bootstrap with OX colors. */

$body-color: #536060;
$gray-800: #2c3738;

$success: #32800C;
$warning: #957814;
$danger: #A82A46;
$info: #22547C;
$muted: #A0B1B1;

// Sets bootstrap to use 24 columns instead of 12 in the grid
$grid-columns: 24;
$grid-gutter-width: 15px;

// Enables practical use of <small>.
$small-font-size: 90%;

@import "bootstrap/scss/bootstrap";

.ox-button {
  line-height: unset; // Unset the .ox-button line-height of 1.5rem so that defaults apply instead
}

.ox-icon-small {
  font-size: 1rem;
}

body {
  background-color: var(--ox-color-grey--light-80);
  font-family: Roboto, Arial, sans-serif;
}

.highlighted {
  background-color: yellow;
}

a:hover {
  text-decoration: none;
}

.ox-link {
  font-weight: inherit;
}
